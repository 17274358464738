import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`Dear Students,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`[AAA---Short debrief or grading status]`}</em></strong></p>
    <p>{`This week we continue our readings from Garrett, focusing on the Structure plane. We also conduct user research for Project 2, for which you should have submitted proposals before the break. Make sure to get going on your research ASAP this week and follow the requirements outlined in the deliverable, especially noting the appendix in which we expect to see your advanced plans for research along with data you collect. Please do not put it off, but pace yourself to do this well! The task flow diagram will be a logical followup and will keep its current due date for next Friday.`}</p>
    <p>{`Please let me know if you have any questions or concerns!`}</p>
    <p>{`Best,
`}<strong parentName="p"><em parentName="strong">{`[BBB---Prof name]`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      